import { router } from '@inertiajs/react';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useEffect } from 'react';

import { useForm } from '@/components/form-context';

export default function useInertiaForm(
  input,
  errors,
  url = null,
  successMessage = 'Changes are saved.',
  checkUnsavedChanges = true,
  options = {
    preserveState: false,
    successCallback: () => {},
  },
) {
  const form = useForm({ initialErrors: errors, validateInputOnBlur: true, ...input });

  // Set errors from controller
  useEffect(() => errors && form.setErrors(errors), [errors]);

  // Show warning if page has changes
  if (checkUnsavedChanges) {
    useEffect(() => {
      return router.on('before', event => {
        if (
          form.isDirty() &&
          event.detail.visit.method !== 'post' &&
          !confirm('You have unsaved changes. Are you sure you want to navigate away?')
        ) {
          event.preventDefault();
        }
      });
    }, [form.isDirty()]);
  }

  form.handleSubmit = () => {
    notifications.clean();
    const formValidation = form.validate();

    if (formValidation.hasErrors) {
      notifications.show({
        color: 'red',
        title: 'Validation error',
        icon: <IconX size="1rem" />,
        message: Object.values(formValidation.errors)[0],
      });
      return;
    }

    router.post(url || window.location.href, form.values, {
      preserveState: page => (options?.preserveState ? true : Object.keys(page.props.errors).length), // if page has errors
      // preserveState: true,
      preserveScroll: true,
      onSuccess: () => {
        if (successMessage) {
          notifications.show({
            color: 'green',
            title: 'Success',
            icon: <IconCheck size="1rem" />,
            message: successMessage,
          });
        }
        options?.successCallback();
      },
      onError: response => {
        notifications.show({
          color: 'red',
          title: 'Error',
          icon: <IconX size="1rem" />,
          message: Object.values(response)[0],
        });
      },
    });
  };

  return form;
}
